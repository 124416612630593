/**
 * Author: 照澄 <zhaocheng.zwj@alibaba-inc.com>.
 * Date: 2016/10/10
 * Copyright(c) 2016 Taobao.com
 */
const Events = require('events');
const Utils = require('../../lib/utils');

module.exports =  class Customized extends Events {
  constructor(ui) {
    super();
    this.ui = ui;
    this.__renderLayout();
  }

  __renderLayout() {
    const ui = this.ui;

    if (!ui.config.tpl) {
      console.log('请在初始化 config 里，配置自定义模板！');
      return;
    }

    this.$elem = Utils.HtmlToDom(ui.config.tpl);

    ui.$container.insertBefore(this.$elem, ui.dialog.$elem);
  }
}