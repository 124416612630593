/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * 岂几 <akai.lk@alibaba-inc.com>
 * Date: 2018/2/27
 * Copyright(c) 2018 Taobao.com
 */
import Tracker from '@ali/tracker';

const polyfill = require('./lib/polyfill');
const Services = require('./api/services');
const Log = require('./lib/log');
const UI = require('./ui');

const tracker = new Tracker({
  pid: 'alicare-dialog',
  sampleRate: 0.1,
});
tracker.onGlobalError(); // 监听全局 JS 异常

const STORAGE_CONFIG_KEY = 'alicare:miniConfig';
const STORAGE_TIME_KEY = 'alicare:timestamp';
const STORAGE_FROM_LIST = ['localTest', 'pretest'].concat([
  'tb-list_bought_items',
  'tb-cashHongbao',
  'tb-trade_replace',
  'tmall-orderDetail',
]);

class Main {
  constructor(options) {
    this.__getInitData(options);
  }

  __getInitData(options) {
    const config = options;

    options.requestHost && Services.setRequestHost(options.requestHost);

    let isStorageVaild = true;
    let isInStorageList = STORAGE_FROM_LIST.some(f => f === config.from);
    const fetchData =
      JSON.parse(localStorage.getItem(STORAGE_CONFIG_KEY)) || {};
    const fetchTime = localStorage.getItem(STORAGE_TIME_KEY) || 0;
    const isSameFrom = fetchData.from === config.from;
    // 超过1分钟，缓存失效
    if ((+new Date() - fetchTime) / (60 * 1000) - 5 > 0) {
      isStorageVaild = false;
    }
    // 不在缓存来源列表里 || 没有缓存数据 || 缓存失效 || 不是同源
    if (!isInStorageList || !fetchData || !isStorageVaild || !isSameFrom) {
      // 重新获取最新配置
      Services.getAlicareMiniConfig(
        options,
        res => {
          if (res.success && res.data) {
            this.__formatParam(res.data, config);
            // 设置本地缓存
            localStorage.setItem(STORAGE_CONFIG_KEY, JSON.stringify(res.data));
            localStorage.setItem(STORAGE_TIME_KEY, +new Date());
          }
        },
        error => {
          tracker.log({
            code: 11,
            msg: 'miniConfigError',
            c1: JSON.stringify(options),
            c2: JSON.stringify(error),
          });
        }
      );
      tracker.log({
        code: 11,
        msg: '获取线上配置',
        c1: JSON.stringify(options),
      });
    } else {
      // 本地缓存初始化
      this.__formatParam(fetchData, config);
      tracker.log({
        code: 11,
        msg: '获取本地配置',
        c1: JSON.stringify(fetchData),
      });
    }
  }

  __formatParam(data, config) {
    const {
      viewType,
      recommendQuestions,
      bu,
      color,
      robotCode,
      robotScene,
      graySwitch,
      grayPercent,
      avatar,
      overview,
      detailTitle,
      detailFooter,
    } = data;

    config.type = viewType;
    config.recommendQuestions = recommendQuestions;
    config.bu = bu;
    config.color = color;
    // 配合PC小蜜init接口
    config.robotScene = robotScene;
    config.robotCode = robotCode;
    config.grayPercent = +(grayPercent || 0);
    config.graySwitch = graySwitch;
    config.avatar = avatar;
    config.overview = overview;
    config.detailTitle = detailTitle;
    config.detailFooter = detailFooter;
    config._user_access_token = config.accessToken;

    if (config.accessToken) {
      delete config.accessToken;
    }

    this.__init(config);
  }

  __init(config) {
    Log.setLog({
      from: config.from,
      memberType: '',
      imsid: '',
    });
    this.ui = new UI(config);
  }

  onRendered(callback) {
    let self = this;
    if (callback && typeof callback === 'function') {
      setTimeout(function() {
        self.ui ? callback(self.ui) : self.onRendered(callback);
      }, 300);
    }
  }
}

module.exports = Main;

window.alicareDialogAsyncInit && window.alicareDialogAsyncInit(Main);
