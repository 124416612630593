/**
 * User: 洗银 <zen.dz@alibaba-inc.com>.
 * Date: 2016/8/15
 * Copyright(c) 2016 Taobao.com
 */
const utils = require('./utils');

module.exports = {
    frameDuration: 20,
    
    opacityUnit: 0.1,
    
    positionUnit: 20,
    
    defaultTimes: 7,
    
    showDialog (ele, cb) {
        const times = this.defaultTimes;
        const oriOpacity = 0.1;
        const oriRight = -80;
        const finOpacity = 1;
        const finRight = 12;

        ele.parentNode.style.display = 'block';
        
        // Set opacity and right position of dialog inner
        clearInterval(this.showDialogId);
        let opacity = oriOpacity;
        let right = oriRight;
        const opacityUnit = Math.ceil(Math.abs(finOpacity - oriOpacity) / times);
        const positionUnit = Math.ceil(Math.abs(finRight - oriRight) / times);

        this.showDialogId = setInterval(() => {
            opacity = opacity + opacityUnit > finOpacity ? finOpacity : opacity + opacityUnit;
            right = right + positionUnit > finRight ? finRight : right + positionUnit;
            ele.style.opacity = opacity;
            ele.style.right = `${right}px`;
            if (right >= finRight) {
                clearInterval(this.showDialogId);
                if (typeof cb === 'function') {
                    cb();                            
                }
            }
        }, this.frameDuration);
    },
    
    closeDialog (ele, cb) {
        const times = this.defaultTimes;
        const oriOpacity = 1;
        const oriRight = 12;
        const finOpacity = 0.1;
        const finRight = -80;
        
        // Set opacity and right position of dialog inner
        clearInterval(this.closeDialogId);
        let opacity = oriOpacity;
        let right = oriRight;
        const opacityUnit = Math.ceil(Math.abs(finOpacity - oriOpacity) / times);
        const positionUnit = Math.ceil(Math.abs(finRight - oriRight) / times);
        
        this.closeDialogId = setInterval(() => {
            opacity = opacity - opacityUnit <= finOpacity ? finOpacity : opacity - opacityUnit;
            right = right - positionUnit <= finRight ? finRight : right - positionUnit;
            ele.style.opacity = opacity;
            ele.style.right = `${right}px`;
            if (right <= finRight) {
                clearInterval(this.closeDialogId);
                ele.parentNode.style.display = 'none';
                if (typeof cb === 'function') {
                    cb();                            
                }
            }
        }, this.frameDuration);
    },

    changeEnvironment (beforeEle, afterEle, parentEle, cb) {
        beforeEle.style.position = 'absolute';
        afterEle.style.position = 'absolute';

        const winSize = utils.getScreenWidthAndHeight();
        const parentBou = parentEle.getBoundingClientRect();

        const oriBeforeVerPos = 0;
        const finAfterVerPos = 0;
        let oriAfterVerPos = winSize.height - parentBou.bottom;
        let finBeforeVerPos = winSize.height - parentBou.bottom + 64;

        if (parentEle.style.left) {
            beforeEle.style.left = '0px';
            afterEle.style.left = '0px';
        } else {
            beforeEle.style.right = '0px';
            afterEle.style.right = '0px';
        }

        let attrName;
        if (parentEle.style.top) {
            attrName = 'top';
        } else {
            attrName = 'bottom';
            finBeforeVerPos = - finBeforeVerPos - beforeEle.clientHeight;
            oriAfterVerPos = -oriAfterVerPos - afterEle.clientHeight;
        }

        beforeEle.style[attrName] = `${oriBeforeVerPos}px`;
        afterEle.style[attrName] = `${oriAfterVerPos}px`;

        this.attrChange(beforeEle, attrName, oriBeforeVerPos, finBeforeVerPos, 7, 'toFast', () => {
            this.attrChange(afterEle, attrName, oriAfterVerPos, finAfterVerPos, 5, 'toSlow', () => {
                const attrArr = ['position', 'top', 'right', 'left', 'bottom'];
                this.clearAttrs(beforeEle, attrArr);
                this.clearAttrs(afterEle, attrArr);
                if (typeof cb === 'function') {
                    cb();
                }
            });
        });
    },

    attrChange (ele, attrName, oriAttr, finAttr, times, mode, cb) {
        const callback = cb || (mode === 'function' ? mode : cb);
        clearInterval(this.attrChangeId);
        let attr = oriAttr;
        let attrUnit = Math.ceil(Math.abs(finAttr - oriAttr) / times);

        let unitIncrease = 0;
        if (mode === 'toFast') {
            unitIncrease = 1;
        } else if (mode === 'toSlow') {
            unitIncrease = -1;
        }

        if (finAttr < oriAttr) {
            attrUnit = -attrUnit;
            unitIncrease = -unitIncrease;
        }

        this.attrChangeId = setInterval(() => {
            attr = (attr + attrUnit >= finAttr && finAttr > oriAttr) ||
            (attr + attrUnit <= finAttr && finAttr < oriAttr) ? finAttr : attr + attrUnit;
            attrUnit += unitIncrease;
            ele.style[attrName] = `${attr}px`;
            if ((attr >= finAttr && finAttr > oriAttr) || (attr <= finAttr && finAttr < oriAttr)) {
                clearInterval(this.attrChangeId);
                if (typeof callback === 'function') {
                    callback();
                }
            }
        }, this.frameDuration);
    },

    clearAttrs (ele, attrArr) {
        for (let i = 0;i < attrArr.length;i++) {
            ele.style[attrArr[i]] = '';
        }
    },
};