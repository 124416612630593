/**
 * User: 洗银 <zen.dz@alibaba-inc.com>.
 * Date: 2016/9/9
 * Copyright(c) 2016 Taobao.com
 */
var nanoid = require('nanoid');

let config = {};

const sid = nanoid(32);
const uid = nanoid(16);

module.exports = {
  sendLog(data) {
    const img = new Image();
    const id = `img_${Math.random()}`;
    const ts = new Date().getTime();
    const from = config.from || 'unknown';
    const page = window.location.href.split('?')[0];
    const query = window.location.search;
    const hash = window.location.hash;
    const biz = 'oTInL';
    const param = {
      ...data,
      ...{
        c: 'dialog',
        ts,
        from,
        page,
        query,
        hash,
        biz,
        uid,
        sid,
        logType: data.logType || 'event',
      },
    };
    const src = `//airunit.taobao.com/tracker.2.0.gif?biz=${biz}&param=${encodeURIComponent(
      JSON.stringify(param)
    )}`;

    window[id] = img;
    img.setAttribute('src', src);
    img.onload = img.onerror = function() {
      window[id] = null;
    };
  },
  setLog(data) {
    config = data;
  },
};
