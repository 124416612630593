/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * Date: 2016/8/1
 * Copyright(c) 2016 Taobao.com
 */
const Events = require('events');
const Utils = require('../../lib/utils');

module.exports = class Weak extends Events {
  constructor(ui) {
    super();
    this.ui = ui;
    this.__renderLayout();
    this.__bindEvents();
  }

  __renderLayout() {
    let ui = this.ui;
    const { avatar, overview } = ui.config;
    const img =
      avatar ||
      'https://gw.alicdn.com/tfs/TB1aEbypsfpK1RjSZFOXXa6nFXa-72-72.svg';
    const tpl = `
      <div class="J_weak">
        <img src="${img}" class="alime-avatar" />
        <span class="alime-text">${overview || 'Need Help?'}</span>
      </div>
    `;
    this.$elem = Utils.HtmlToDom(tpl);
    ui.$container.insertBefore(this.$elem, ui.dialog.$elem);

    this.toggleActive();
  }

  toggleActive() {
    let ui = this.ui,
      $elem = this.$elem;
    if (ui.dialog.isShow) {
      Utils.addClass($elem, 'active');
    } else {
      Utils.removeClass($elem, 'active');
    }
  }

  __bindEvents() {
    let ui = this.ui;

    Utils.bindEvent(
      this.$elem,
      'click',
      ui.draggable.wrapClickFunc(() => {
        if (ui.dialog.isShow) {
          ui.emit('closeDialog', { isOwnEvent: true });
        } else {
          ui.emit('openDialog', { isOwnEvent: true });
        }
        this.toggleActive();
      })
    );
  }
};
