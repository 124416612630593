/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * Date: 2016/8/1
 * Copyright(c) 2016 Taobao.com
 */
const Events = require('events');
const Utils = require('../../lib/utils');
const Log = require('../../lib/log');

module.exports = class Strong extends Events {
  constructor(ui) {
    super();
    this.ui = ui;
    this.__renderLayout();
    this.__bindEvents();
  }

  __renderLayout() {
    const ui = this.ui;
    const { detailTitle, detailFooter } = ui.config;
    let questionHtml = '';
    const recommendQuestions = ui.config.recommendQuestions;
    const avatar =
      ui.config.avatar ||
      'https://gw.alicdn.com/tfs/TB1aEbypsfpK1RjSZFOXXa6nFXa-72-72.svg';
    const robotName = detailTitle || 'Alime Assistant';
    const tpl = `
      <div class="J_strong">
        <div class="alime-strong-header alime-draggable">
            <img src="${avatar}" class="alime-avatar" />
            <span class="alime-text">${robotName}</span>
            <span class="close-icon"></span>
        </div>
        <ul class="alime-question-list"></ul>
        <p class="alime-strong-consult">
            ${detailFooter || 'Need Help? Ask Me'}
        </p>
      </div>
    `;

    this.$elem = Utils.HtmlToDom(tpl);

    Array.prototype.forEach.call(
      (recommendQuestions || []).slice(0, 5),
      (item, idx) => {
        questionHtml += `<li data-id="${item.knowledgeId}" data-index="${idx +
          1}">${item.question}</li>`;
      }
    );

    this.$elem.querySelector('ul').innerHTML = questionHtml;

    ui.$container.insertBefore(this.$elem, ui.dialog.$elem);
  }

  __bindEvents() {
    let $elem = this.$elem,
      ui = this.ui;
    const recommendQuestions = ui.config.recommendQuestions;
    const $closeIcon = $elem.querySelector('.close-icon');
    Utils.bindEvent(
      $closeIcon,
      'click',
      ui.draggable.wrapClickFunc(() => {
        Log.sendLog({ d: 'mini' });
        ui.emit('closeStrong', { isOwnEvent: true });
      })
    );

    const $list = $elem.querySelectorAll('.alime-question-list li');
    Array.prototype.forEach.call($list || [], item => {
      Utils.bindEvent(
        item,
        'click',
        ui.draggable.wrapClickFunc(ev => {
          let $item = ev.currentTarget;
          const index = parseInt($item.dataset.index, 10) - 1;
          Log.sendLog({
            d: 'click_question',
            position: index,
            text: item.innerHTML,
            ext: {
              knowledgeId: $item.dataset.id,
            },
          });
          ui.emit('message', recommendQuestions[index].question, {
            isOwnEvent: true,
          });
        })
      );
    });

    const $consultLink = $elem.querySelector('.alime-strong-consult');
    Utils.bindEvent(
      $consultLink,
      'click',
      ui.draggable.wrapClickFunc(() => {
        ui.emit('toggleDialog', { isOwnEvent: true });
      })
    );
  }
};
