/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * Date: 2016/8/1
 * Copyright(c) 2016 Taobao.com
 */
const Events = require('events');
const Weak = require('./weak');
const Strong = require('./strong');
const Customized = require('./customized');
const Dialog = require('./dialog');
const Utils = require('../lib/utils');
const Draggable = require('../lib/draggable');
const Animation = require('../lib/animation');
const Log = require('../lib/log');

require('./style.scss');

module.exports = class UI extends Events {
  constructor(config) {
    super();
    this.config = config;

    this.__renderContainer();
    this.__initDialog();
    this.__initLayout();

    this.__bindEvent();
  }

  __renderContainer() {
    const config = this.config;

    const $container = document.createElement('div');
    $container.id = 'J_xiaomi_dialog';
    document.body.appendChild($container);
    $container.style.zIndex = config['z-index'] || '999999';

    this.$container = $container;

    this.__initPosition();
    this.__initColor();
    this.__initDraggable();
  }

  __initDraggable() {
    const config = this.config;

    if (config.undraggable) {
      this.draggable = {
        wrapClickFunc(cb) {
          return cb;
        },
      };
    } else {
      this.draggable = new Draggable({
        draggerElement: this.$container,
        maskSelector: '.dialog-fake-mask',
      });
    }
  }

  __bindEvent() {
    this.on('openDialog', (config = {}) => {
      const { isOwnEvent } = config;
      this.__checkEmit({
        isOwnEvent,
        emitEvent: this.__openAlicareDialog,
      });
    });

    this.on('closeDialog', (config = {}) => {
      const { isOwnEvent, callback } = config;
      this.__checkEmit({
        isOwnEvent,
        callback,
        emitEvent: this.__closeAlicareDialog,
      });
    });

    this.on('message', (message, config = {}) => {
      const { isOwnEvent } = config;
      this.__checkEmit({
        isOwnEvent,
        message,
        emitEvent: this.__openAlicareDialog,
      });
    });

    this.on('toggleDialog', (config = {}) => {
      const { isOwnEvent } = config;
      this.__checkEmit({
        isOwnEvent,
        emitEvent: this.__toggleDialog,
      });
    });

    this.once('closeStrong', (config = {}) => {
      const { isOwnEvent } = config;
      this.__checkEmit({
        isOwnEvent,
        emitEvent: this.__changeEnvironment,
      });
    });
  }

  __checkEmit(config) {
    const isCustomized = this.config.type === 'customized';
    const { isOwnEvent, emitEvent } = config;

    if (!emitEvent || typeof emitEvent !== 'function') return;

    // 不是自定义类型需要确认是否是内部触发
    if (!isCustomized && isOwnEvent) {
      emitEvent.call(this, config);
    }
    // 如果是自定义调用，触发有效
    else if (isCustomized) {
      emitEvent.call(this, config);
    }
  }

  __initPosition() {
    const config = this.config;
    const position = {};
    const posAttrs = ['left', 'right', 'top', 'bottom'];
    const windowHeight = document.documentElement.clientHeight;
    const windowWidth = document.documentElement.clientWidth;

    const { isNumber, isPercentage } = Utils;
    const isPosValid = val => parseInt(val || 0, 10) !== 0;

    //通过提供的 position 初始化位置
    if (config.position) {
      for (let attr in config.position) {
        if (posAttrs.indexOf(attr) !== -1) {
          let configPosVal = config.position[attr];
          // 最小值为 0 不允许负值 无论数值或百分比
          position[attr] = parseInt(configPosVal, 10) < 0 ? 0 : configPosVal;
        }
      }
    }
    //通过提供的 container 初始化位置，必须提供容器
    else if (config.container && config.container.selector) {
      const $container = document.querySelectorAll(
        config.container.selector
      )[0];

      if (!Utils.isDom($container)) return;

      const containerRect = $container.getBoundingClientRect();

      const posAttr = config.container.align === 'left' ? 'right' : 'left';

      position[posAttr] =
        containerRect[posAttr] + (posAttr === 'left' ? containerRect.width : 0);

      // 通过提供的 offset 作相对容器的位置偏移
      // 做配置兼容 = =
      if (config.offset || config.container.offset) {
        let offset = config.offset || config.container.offset;

        if (isNumber(offset.x)) {
          if (isNumber(position.left)) {
            position.left += offset.x;
          } else {
            position.right -= offset.x;
          }
        }

        if (isNumber(offset.y)) {
          if (isNumber(position.top)) {
            position.top += offset.y;
          } else if (isNumber(position.bottom)) {
            position.bottom -= offset.y;
          } else {
            position.bottom = 50 - offset.y;
          }
        }
      }
    }

    // 设置默认距离右边 50px
    if (!isPosValid(position.left) && !isPosValid(position.right)) {
      position.right = 40;
    }
    // 设置默认距离底部 50px
    if (!isPosValid(position.top) && !isPosValid(position.bottom)) {
      position.bottom = 50;
    }
    // 同时设置 top & bottom 时，top 优先
    if (isPosValid(position.top) && isPosValid(position.bottom)) {
      position.bottom = null;
    }
    // 同时设置 left & right 时，left 优先
    if (isPosValid(position.left) && isPosValid(position.right)) {
      position.right = null;
    }

    for (let attr in position) {
      let value = position[attr];
      // 只允许数值或百分比字符串
      if (isNumber(value)) {
        // 直接设置数值，单位默认 px
        this.$container.style[attr] = value === 0 ? null : `${value}px`;
      } else if (isPercentage(value)) {
        // 百分比位置
        this.$container.style[attr] = value;
      }
    }
  }

  __initColor() {
    const color = this.config.color || '#f7931e';
    const tpl = `#J_xiaomi_dialog .J_weak:hover {
                        color: ${color}; 
                     }
                     #J_xiaomi_dialog .J_strong ul li:hover{
                        color: ${color};
                     }
                     #J_xiaomi_dialog .J_strong .alime-strong-consult {
                        color: ${color}; 
                     }`;
    const $style = document.createElement('style');
    $style.setAttribute('type', 'text/css');

    // hack for IE
    if ($style.styleSheet) {
      $style.styleSheet.cssText = tpl;
    } else {
      $style.innerHTML = tpl;
    }

    const $styles = document.getElementsByTagName('style');
    $styles[$styles.length - 1].parentNode.appendChild($style);
  }

  __initLayout() {
    let { type, recommendQuestions } = this.config;

    switch (type) {
      case 'strong':
        if (recommendQuestions && recommendQuestions.length !== 0) {
          this.__initStrong();
        } else {
          this.__initWeak();
        }
        break;
      case 'customized':
        this.__initCustomized();
        break;
      case 'weak':
      default:
        this.__initWeak();
        break;
    }

    // Log.sendLog({ d: 'init_' + type || 'weak' });
  }

  __initDialog() {
    this.dialog = new Dialog(this);
    Log.sendLog({ pageTitle: 'alime dialog', logType: 'pageview' });
  }

  __initWeak() {
    this.weak = new Weak(this);
    this.layout = this.weak.$elem;
  }

  __initStrong() {
    this.strong = new Strong(this);
    this.layout = this.strong.$elem;
  }

  __initCustomized() {
    this.customized = new Customized(this);
    this.layout = this.customized.$elem;
  }

  __changeEnvironment(config) {
    const { callback } = config;
    if (!this.weak) {
      this.__initWeak();
    }

    if (this.dialog.isShow) {
      this.__closeAlicareDialog(config);
    }

    Animation.changeEnvironment(
      this.strong.$elem,
      this.weak.$elem,
      this.$container,
      () => {
        Utils.remove(this.strong.$elem);
        delete this.strong;
      }
    );
  }

  __openAlicareDialog(config) {
    const { message } = config;
    this.dialog.show(message);
  }

  __closeAlicareDialog(config) {
    const { callback } = config;
    this.dialog.hide(callback);
  }

  __toggleDialog(config) {
    this.dialog.isShow
      ? this.__closeAlicareDialog(config)
      : this.__openAlicareDialog(config);
  }
};
