/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * Date: 2016/8/1
 * Copyright(c) 2016 Taobao.com
 */
const Events = require('events');
const Services = require('../../api/services');
const Utils = require('../../lib/utils');
const Log = require('../../lib/log');
const Animation = require('../../lib/animation');

// 我的淘宝-我的优惠信息-优惠卡券
// 一淘
// 灰度融合版
const PRE_FROM_LIST = ['localTest', 'pretest'].concat([
  'tb-got_bonus',
  'fetao_pcindex',
  'damai_pc',
  'damai_pc_channel',
]);

const URL = {
  daily: '//service.daily.taobao.net/support/alicare/index.htm?',
  pre: '//service.taobao.com/support/alicare/index.htm?',
  beta: '//service.taobao.com/support/alicare/index.htm?',
  prod: '//service.taobao.com/support/alicare/index.htm?',
};

const NEW_URL = {
  daily: '//wapp.waptest.taobao.com/alicare/alicarePC.html?',
  pre: '//wapp.wapa.taobao.com/alicare/alicarePC.html?',
  beta: '//wapp.m.taobao.com/alicare/alicarePC.html?',
  prod: '//h5.m.taobao.com/alicare/alicarePC.html?',
};

const defaultIframeSrc = 'https://ai.alimebot.taobao.com/intl/index.htm';

module.exports = class Dialog extends Events {
  constructor(ui) {
    super();

    this.ui = ui;
    this.config = ui.config;
    this.isShow = false;
    this.iframeSrc = null;

    this.__renderDialog();
    this.__bindEvents();
    this.__setIframeSrc();
  }

  __renderDialog() {
    let ui = this.ui;
    const tpl = `<div class="J_Dialog">
            <div class="alicare-dialog-wrapper">
                <div class="dialog-fake-mask"></div>
                <div class="alicare-fake-header alicare-draggable">
                    <a class="alicare-im-close">
                      <img src="//gw.alicdn.com/tfs/TB1lWlNOkvoK1RjSZPfXXXPKFXa-29-29.svg">
                    </a>
                </div>
                <iframe width="460" height="550" class="alicare-frame" name="alicare-dialog" src="about:blank"></iframe>
            </div>
        </div>`;
    this.$elem = Utils.HtmlToDom(tpl);
    this.$dialog = this.$elem.querySelector('.alicare-dialog-wrapper');

    this.$iframe = this.$elem.querySelector('.alicare-frame');
    this.$iframe.frameBorder = 0;

    ui.$container.appendChild(this.$elem);
  }

  __setIframeSrc() {
    const config = this.config;
    const env = Utils.getEnvironment();
    // const isPreFrom = PRE_FROM_LIST.some(f => f === config.from);
    // 预设灰度 from
    // let iframeSrc = isPreFrom ? NEW_URL[env] : URL[env];
    // 接口配置灰度
    // let iframeSrc = '';
    // const { graySwitch, grayPercent } = config;
    // if (graySwitch && Math.random() * 100 < grayPercent) {
    //     iframeSrc = NEW_URL[env];
    // } else {
    //     iframeSrc = URL[env];
    // }

    // 全量新版 PC
    let iframeSrc = `${config.botUrl || defaultIframeSrc}?`;

    // 添加参数
    const params = [
      'from',
      'bu',
      'orderId',
      'activeScroll',
      'robotScene',
      'robotCode',
      '_user_access_token',
    ];

    iframeSrc += params
      .filter(key => !!config[key])
      .map(key => `${key}=${encodeURIComponent(config[key])}`)
      .join('&');

    // params.forEach(key => {
    //   if (!!config[key]) {
    //     iframeSrc += `&${key}=${encodeURIComponent(config[key])}`;
    //   }
    // });

    // 自定义参数
    if (
      Object.prototype.toString.call(config.params).slice(8, -1) === 'Object'
    ) {
      Object.keys(config.params).forEach(key => {
        iframeSrc += `&${key}=${encodeURIComponent(config.params[key])}`;
      });
    }

    this.iframeSrc = iframeSrc;
  }

  __updatePositon() {
    let $container = this.ui.$container,
      offsetBottom = -8,
      offsetY = 0;
    let containerTop, containerBottom, containerLeft, containerRight;

    const windowHeight = document.documentElement.clientHeight;
    const windowWidth = document.documentElement.clientWidth;
    const layoutHeight = this.ui.layout ? this.ui.layout.clientHeight : 0;
    const layoutWidth = this.ui.layout ? this.ui.layout.clientWidth : 0;
    const dialogHeight = this.$elem.clientHeight;
    const dialogWidth = this.$dialog.clientWidth;

    const convertPerToFloat = per => parseInt(per, 10) / 100;
    const calPosVal = (posVal = 0, range = 0) => {
      let calVal = 0;
      if (Utils.isPercentage(posVal)) {
        calVal = convertPerToFloat(posVal) * range;
      } else {
        calVal = parseInt(posVal, 10);
      }
      return calVal;
    };

    // 获取dialog配置
    if (this.config.dialog && this.config.dialog.offset) {
      offsetY = this.config.dialog.offset.y;
      if (offsetY && offsetY < 0) {
        offsetBottom += offsetY;
      }
    }

    // 获取元素容器上下位移
    if ($container.style.top) {
      containerTop = calPosVal($container.style.top, windowHeight);
      containerBottom = windowHeight - containerTop - layoutHeight;
    } else if ($container.style.bottom) {
      containerBottom = calPosVal($container.style.bottom, windowHeight);
      containerTop = windowHeight - containerBottom - layoutHeight;
    }

    let offsetTop = containerTop + layoutHeight + 4 - dialogHeight;

    // 如果对话框底部超出可视范围，将对话框上移
    if (containerBottom < -offsetBottom) {
      offsetBottom = -containerBottom;
    }

    // 优先展示头部
    // 如果对话框头部超出可视范围，将对话框下移
    if (offsetTop < 0) {
      offsetBottom = offsetTop > offsetBottom ? offsetBottom : offsetTop;
    }

    this.$elem.style.bottom = `${offsetBottom}px`;

    // 获取元素容器左右位移
    if ($container.style.left) {
      containerLeft = calPosVal($container.style.left, windowWidth);
    } else if ($container.style.right) {
      containerRight = calPosVal($container.style.right, windowWidth);
      containerLeft = windowWidth - containerRight - layoutWidth;
    }

    if (containerLeft < dialogWidth) {
      $container.style.left = `${dialogWidth + 8}px`;
    }
  }

  __bindEvents() {
    let ui = this.ui;
    const closeIcon = this.$elem.querySelector('.alicare-im-close');
    Utils.bindEvent(
      closeIcon,
      'click',
      ui.draggable.wrapClickFunc(() => {
        ui.emit('closeDialog', { isOwnEvent: true });
      })
    );
  }

  show(message) {
    if (!this.isShow) {
      // 禁止预加载
      if (this.$iframe.src === 'about:blank' && this.iframeSrc) {
        this.$iframe.src = message
          ? `${this.iframeSrc}&attemptquery=${encodeURIComponent(message)}`
          : this.iframeSrc;
      }

      Animation.showDialog(this.$dialog);

      this.__updatePositon();
    }

    if (message) {
      Services.sendMessage.call(this.$iframe, message);
    }

    this.isShow = true;
    Log.sendLog({ d: 'open_chat' });
  }

  hide(cb) {
    let ui = this.ui;

    if (this.isShow) {
      Animation.closeDialog(this.$dialog, cb);
    }

    this.isShow = false;

    ui.weak && ui.weak.toggleActive();

    Log.sendLog({ d: 'close_chat' });
  }
};
