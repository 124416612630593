/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * 岂几 <akai.lk@alibaba-inc.com>
 * Date: 2018/2/27
 * Copyright(c) 2018 Taobao.com
 */
const Jsonp = require('../lib/jsonp');
const Utils = require('../lib/utils');

// const configSuccess = {
//     data: {
//         from: 'test',
//         viewType: 'customized',
//         color: '#000',
//         forecast: '',
//         recommendQuestions: [
//             {
//                 knowledgeId: 5658022,
//                 question: '第一条热点问题',
//             },
//             {
//                 knowledgeId: 5658023,
//                 question: '第二条热门知识',
//             },
//         ],
//     },
//     message: 'success',
//     status: 0,
// };

// const env = Utils.getEnvironment();
// const configUrls = {
//   daily: '//openair.daily.taobao.net/app/dialog_config',
//   pre: 'https://pre-air.taobao.com/app/dialog_config',
//   beta: 'https://airunit.taobao.com/app/dialog_config',
//   prod: 'https://airunit.taobao.com/app/dialog_config',
// };
// const configUrl = configUrls[env];

let hostUrl = 'https://ai.alimebot.taobao.com';

module.exports = {
  setRequestHost(host) {
    hostUrl = host;
  },
  sendMessage(message) {
    if (Utils.isIframe(this)) {
      this.contentWindow.postMessage(
        JSON.stringify({
          message,
          source: 'alicare-dialog',
        }),
        '*'
      );
    }
  },
  getAlicareMiniConfig(config, callback, errorHandle) {
    Jsonp.get(
      `${hostUrl}/api/home/dialogconfig`,
      {
        from: config.from,
        orderId: config.orderId,
        sourceURL: window.location.href,
      },
      callback,
      errorHandle
    );
  },
};
