/**
 * Author: 
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * Date: 2016/8/2
 * Copyright(c) 2016 Taobao.com
 */

module.exports = {
    /**
     * 获取当前环境
     * @returns {*}
     */
    getEnvironment() {
      let env = 'prod';
      const hostname = location.hostname;

      if (/daily|waptest/g.test(hostname)
        || hostname.indexOf('waptest.taobao.com') > -1) {
        // 日常
        env = 'daily';
      } else if (hostname.indexOf('wapa.taobao.com') > -1) {
        // 预发
        env = 'pre';
      } else if (hostname.indexOf('wapp.m.taobao.com') > -1) {
        // beta 版 哎哟喂
        env = 'beta';
      }

      return env;
    },
    HtmlToDom (tpl) {
        const auxDiv = document.createElement('div');
        auxDiv.innerHTML = tpl;
        return auxDiv.children[0];
    },
    bindEvent (element, method, callbackFn) {
        if (element.addEventListener) {
            element.addEventListener(method, callbackFn, false);
        } else if (element.attachEvent) {
            element.attachEvent(`on${method}`, callbackFn);
        }
    },
    haltEvent (ev) {
        const event = ev || window.event;
        if (event.preventDefault) {
            event.preventDefault();
        } else {
            event.returnValue = false;
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        } else if (event.cancelBubble) {
            event.cancelBubble = true;
        }
    },
    getScreenWidthAndHeight () {
        return {
            width: document.documentElement.clientWidth ||
            document.body.clientWidth,
            height: document.documentElement.clientHeight ||
            document.body.clientHeight,
        };
    },
    computePosition (ev) {
        const event = ev || window.event;
        const pageX = event.pageX || event.clientX + (document.documentElement.scrollLeft ?
                document.documentElement.scrollLeft :
                document.body.scrollLeft);

        const pageY = event.pageY || event.clientY + (document.documentElement.scrollTop ?
                document.documentElement.scrollTop :
                document.body.scrollTop);

        return { pageX, pageY };
    },
    hasClass (element, className) {
        return !!element.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
    },
    addClass (element, className) {
        if (!this.hasClass(element, className)) {
            element.className += ` ${className}`;
        }
    },
    removeClass (element, className) {
        if (this.hasClass(element, className)) {
            const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
            element.className = element.className.replace(reg, ' ');
        }
    },
    remove (element) {
        element.parentNode.removeChild(element);
    },
    isIframe(iframe) {
        return iframe && Object.prototype.toString.call(iframe) === '[object HTMLIFrameElement]';
    },
    isDom(container) {
        return container && container.nodeType === 1;
    },
    isNumber(num) {
        return typeof num === 'number';
    },
    isPercentage(val) {
        return /%$/g.test(val + '');
    }
};