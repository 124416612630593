/**
 * Author:
 * 洗银 <zen.dz@alibaba-inc.com>.
 * 照澄 <zhaocheng.zwj@alibaba-inc.com>
 * Date: 2016/8/3
 * Copyright(c) 2016 Taobao.com
 */
const utils = require('./utils');
const log = require('./log');
/**
 * 将一个 DOMElement 装饰为可被拖曳的元素
 * @type {Draggable}
 * @param draggerElement：需要被拖曳的元素
 */
module.exports = class Draggable {
  constructor(config) {
    this.config = config;
    this.isDragReady = false;
    this.hasDragged = false;

    this.__bindEvent();
  }

  __bindEvent() {
    let { draggerElement, maskSelector } = this.config;

    const dragoffset = { x: 0, y: 0 };
    const checkPosMousedown = { x: 0, y: 0 };

    utils.bindEvent(draggerElement, 'mouseover', ev => {
      const event = ev || window.event;
      const target = event.srcElement || event.target;

      if (!target) {
        return;
      }

      const draggerElemHeight = draggerElement.clientHeight;

      // 自定义类型时禁止拖动
      if (draggerElemHeight === 0) {
        target.style.cursor = '';
        return;
      }

      if (utils.hasClass(target, 'alime-draggable')) {
        target.style.cursor = 'move';
      }
    });

    utils.bindEvent(draggerElement, 'mousedown', ev => {
      const event = ev || window.event;
      const target = event.srcElement || event.target;

      if (!target) {
        return;
      }

      utils.haltEvent(event);

      this.isDragReady = true;
      const maskElement = draggerElement.querySelector(maskSelector);
      if (maskElement) {
        maskElement.style.visibility = 'visible';
      }

      const pos = utils.computePosition(event);

      dragoffset.x = pos.pageX - draggerElement.offsetLeft;
      dragoffset.y = pos.pageY - draggerElement.offsetTop;

      checkPosMousedown.x = event.clientX;
      checkPosMousedown.y = event.clientY;
    });

    utils.bindEvent(document, 'mouseup', ev => {
      this.isDragReady = false;
      const maskElement = draggerElement.querySelector(maskSelector);
      if (maskElement) {
        maskElement.style.visibility = 'hidden';
      }
      if (this.hasDragged) {
        log.sendLog({ d: 'drag' });
        setTimeout(() => {
          this.hasDragged = false;
        }, 60);
        draggerElement.style.cursor = '';
      }
    });

    utils.bindEvent(document, 'mousemove', ev => {
      const event = ev || window.event;
      const target = event.target;
      const checkPosMousemove = {
        x: event.clientX,
        y: event.clientY,
      };

      if (
        checkPosMousedown.x &&
        checkPosMousemove.x &&
        Math.abs(checkPosMousedown.x - checkPosMousemove.x) <= 1 &&
        checkPosMousedown.y &&
        checkPosMousemove.y &&
        Math.abs(checkPosMousedown.y - checkPosMousemove.y) <= 1
      ) {
        return;
      }

      if (this.isDragReady) {
        const draggerElemHeight = draggerElement.clientHeight;

        // 自定义类型时禁止拖动
        if (draggerElemHeight === 0) return;

        const screenWH = utils.getScreenWidthAndHeight();
        const pos = utils.computePosition(event);
        const draggerSize = {
          width: draggerElement.offsetWidth,
          height: draggerElement.offsetHeight,
        };

        let verticalDirection, horizonDirection;
        let vertical = pos.pageY - dragoffset.y;
        let horizon = pos.pageX - dragoffset.x;

        if (horizon > (screenWH.width - draggerSize.width) / 2) {
          horizon = screenWH.width - draggerSize.width - horizon;
          horizonDirection = 'right';
          if (draggerElement.style.left) {
            draggerElement.style.left = '';
          }
        } else {
          horizonDirection = 'left';
          if (draggerElement.style.right) {
            draggerElement.style.right = '';
          }
        }

        if (horizon < 5) {
          horizon = 5;
        }

        draggerElement.style[horizonDirection] = `${horizon}px`;

        if (vertical > (screenWH.height - draggerSize.height) / 2) {
          vertical = screenWH.height - draggerSize.height - vertical;
          verticalDirection = 'bottom';
          if (draggerElement.style.top) {
            draggerElement.style.top = '';
          }
        } else {
          verticalDirection = 'top';
          if (draggerElement.style.bottom) {
            draggerElement.style.bottom = '';
          }
        }

        const $dialog = draggerElement.querySelector('.J_Dialog');
        const windowHeight = document.documentElement.clientHeight;

        const minCriticalValue = {
          top: 10,
          bottom: 10,
        };

        const maxCriticalValue = {
          top: windowHeight,
          bottom: windowHeight,
        };

        if ($dialog && $dialog.style.display === 'block') {
          let dialogBottom = -parseInt($dialog.style.bottom || 0, 10);
          let dialogHeight = $dialog.clientHeight;

          // 最小临界值
          minCriticalValue.top =
            dialogHeight - dialogBottom - draggerElemHeight;
          minCriticalValue.bottom = dialogBottom;

          // 最大相对临界值
          maxCriticalValue.top =
            windowHeight - dialogBottom - draggerElemHeight;
          maxCriticalValue.bottom = windowHeight - dialogHeight + dialogBottom;
        }

        if (vertical < minCriticalValue[verticalDirection]) {
          vertical = minCriticalValue[verticalDirection];
        } else if (vertical > maxCriticalValue[verticalDirection]) {
          vertical = maxCriticalValue[verticalDirection];
        }

        draggerElement.style[verticalDirection] = `${vertical}px`;

        this.hasDragged = true;
        draggerElement.style.cursor = 'move';
      }
    });
  }

  /**
   * click 回调的封装函数，用于防止拖曳结束后触发 click 回调。
   * @param callback
   * @returns {function(*=)}
   */
  wrapClickFunc(callback) {
    return ev => {
      if (!this.hasDragged) {
        callback(ev);
      }
    };
  }
};
