/**
 * User: 洗银 <zen.dz@alibaba-inc.com>.
 * Date: 2016/8/4
 * Copyright(c) 2016 Taobao.com
 */
let counter = 0;
let config = {};
let head;

function load(url, pfnError) {
    const script = document.createElement('script');
    let done = false;
    script.src = url;
    script.async = true;

    const errorHandler = pfnError || config.error;
    if ( typeof errorHandler === 'function' ) {
        script.onerror = function(event) {
            errorHandler({ url, event });
        };
    }

    script.onload = script.onreadystatechange = function() {
        if ( !done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') ) {
            done = true;
            script.onload = script.onreadystatechange = null;
            if ( script && script.parentNode ) {
                script.parentNode.removeChild( script );
            }
        }
    };

    if ( !head ) {
        head = document.getElementsByTagName('head')[0];
    }
    head.appendChild( script );
}

function encode(str) {
    return encodeURIComponent(str);
}

function jsonp(url, params, callback, errorHandle) {
    let query = (url || '').indexOf('?') === -1 ? '?' : '&';
    let key;

    const callbackName = (config.callbackName || 'callback');
    const uniqueName = `${callbackName}_json${++counter}`;

    const parameters = Object.assign(params || {}, {
        _input_charset: 'utf-8'
    });
    for ( key in parameters ) {
        if ( parameters.hasOwnProperty(key) ) {
            query += `${encode(key)}=${encode(parameters[key])}&`;
        }
    }

    window[ uniqueName ] = function(data) {
        callback(data);
        try {
            delete window[ uniqueName ];
        } catch (e) {}
        window[ uniqueName ] = null;
    };

    load(`${url}${query}${callbackName}=${uniqueName}`, errorHandle);
    return uniqueName;
}

function setDefaults(obj) {
    config = obj;
}

module.exports = {
    get: jsonp,
    init: setDefaults,
};